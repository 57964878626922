'use strict'

module.exports = {
  isValidContext() {
    return true
  },

  hideComponent({ component }, { rememberInitiallyHidden = false } = {}) {
    if (!component.hidden) {
      component.hide()
    } else if (rememberInitiallyHidden) {
      this.initiallyHidden = true
    }
  },

  showComponent({ component }, { ignoreInitiallyHidden = false } = {}) {
    if (component.hidden && (!ignoreInitiallyHidden || !this.initiallyHidden)) {
      component.show()
    }
  },

  clearComponent() {},

  bindToComponent() {},

  currentRecordModified() {},

  recordSetLoaded() {},

  currentViewChanged() {},

  currentIndexChanged() {}
}
